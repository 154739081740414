import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
    height: 400px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Text = styled.p`
    display: block;
    font-size: 30px;
    padding: 30px;
`;

const Success = ()=>{
    return(
        <Wrapper>
            <Text>
                感謝您的來信，我們將儘快回復您。
            </Text>
        </Wrapper>
    )
}

export default Success;